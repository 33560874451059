.slideService {
    outline: 0;
    max-width: 80%;
    margin: 2rem auto;
    text-align: center;
}

.slideService > img { width: 420px }

.slideService .card {
    outline: 0;
    width: 90%;
    display: block !important;
}

.slideService .card h2 { font-size: 1.3rem }
.slideService .card h3 { font-size: 1rem; text-align: right; padding-right: 1rem }

.slideService .slick-slide .card h3 { color: #f2b14a ; cursor: url('../../public/img/cursorPointer.png') 1 1, pointer !important; }
.slideService .slick-current .card h3 { color: #444; text-decoration: underline;}

.slideService .slick-current .card div { background: #f2b14a !important }

.slideService .slick-current > div{
    border: 5px solid #f2b14a !important; 
    z-index: 300;
    position: relative;
    margin-bottom: -1rem;
    border-radius: 2rem;
}

.slideService .slick-slide > div {
    margin: 0 1rem;
}

.slideService .slick-slide > div {
    position: relative;
    display: block;
    border: 5px solid #444;
    border-radius: 2rem;
    opacity: .7;
}
.slideService .slick-current > div { opacity: 1 }

.slideService .slick-slide .card > div {
    position: absolute;
    width: 60%;
    color: white;
    background: #444;
    right: 0;
    border-top-right-radius: 1rem;
    border-bottom-left-radius: 2rem;
}

.slideService .slick-slide img{
    display: block;
    border-radius: 2rem !important;
    width: 100%;
    height: 100%;
}

.slideService .slick-initialized .slick-slide { height: auto }


/* Adicione aqui os estilos para o SlideService */
.slideService {
    position: relative;
  }
  
  .slideService .card {
    /* Adicione os estilos do card aqui */
  }
  
  /* Estilos do Modal */
  .slideService .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  .slideService .modal:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: inherit;
    backdrop-filter: blur(5px);
  }
  
  .slideService .modal-content {
    position: absolute;
    top: 2rem;
    display: flex;
    min-width: 50%;
    max-width: 900px;
    max-height: 500px;
    height: 80%;
    background-color: #fff;
    padding: 20px;
    border-radius: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 10
  }

  .slideService .modal-content > div {
    padding: 0 2rem;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    text-align: left;
}

  .slideService .modal-content > img {
    max-width: 60%;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    border-radius: 1rem;
    box-shadow: 1px 3px 6px 1px rgba(0, 0, 0, 0.548);
}

.slideService .modal-content h2 {
    font-family: AvenirBold;
    letter-spacing: 5px;
    font-size: 2rem;
    color: #f2b14a;
    text-transform: uppercase;
}

.slideService .modal-content p {
    color: #444
}

.slideService .modal-content .price {
    position: relative;
}

.slideService .modal-content .price {
    color: #999
}


.slideService .modal-content .price H3:nth-of-type(1){
    display: none !important;
}
.slideService .modal-content h3 {
    position: absolute;
    font-family: AvenirBold;
    font-size: 1.4rem;
    color: #f2b14a;
    display: flex;
    bottom: 1rem;
    font-weight: lighter;
    letter-spacing: 3px;
    text-transform: uppercase;
}
.slideService .modal-content h3:nth-of-type(1) {
    position: absolute;
    font-weight: lighter;
    letter-spacing: 3px;
    bottom: 2.4rem;
    left: -.8rem;
    color: #444 !important;
    transform: scale(.8);
    opacity: .8;
}

.slideService .modal-content h3:nth-of-type(1)::after{
    content: '';
    position: absolute;
    width: 100%;
    top: 50%;
    border-bottom: 2px solid #b9b9b9;
    transform: rotate(5deg);
    opacity: .6;
}

.slideService .modal-content h3:nth-of-type(1)::before{
    content: '';
    position: absolute;
    width: 100%;
    top: 50%;
    border-bottom: 2px solid #818181;
    transform: rotate(-5deg);
    opacity: .6;
}

.slideService .modal-content button {
    width: 100%;
    background: #f2b14a;
    font-size: 1.2rem;
    font-weight: bold;
    letter-spacing: 3px;
    height: 4rem;
    transition: .4s;
    cursor: pointer;
    transition: .4s;
    color: #222;
    border: 2px solid #555;
    border-radius: 1rem;
    text-transform: uppercase;
}
.slideService .modal-content button:hover { background: #ffbb4d; color: #fff }
.slideService .modal-content button:focus { border-color: #333; }
  
  .slideService .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
  }
  
  /* Estilos adicionais para o modal */
  /* Adicione aqui outros estilos conforme necessário */
  

  .slideService .scroll img { display: none }


  @media (max-width: 900px) {
    .slideService > img { width: 100% }
    .slideService .modal-content {
        max-width: 580px !important;
        max-height: 75% !important;
    }
    
    .slideService .modal-content img {
        max-width: 100%;
        width: 280px;
        height: 350px;
    }

    .slideService .text {
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        margin: 1rem 0;
        height: 3rem;
    }

    .slideService .text::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0) !important;
        opacity: 0 !important;
      }
    .slideService .text::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0) !important;
        opacity: 0 !important;
    }

    .slideService .scroll img { display: block ;}
    .slideService .scroll {
        display: block;
        position: relative;
        width: 100%;
        display: flex;
    }
    
    .slideService .scroll .text { width: 97% !important;}
    .slideService .scroll img { 
        box-shadow: none !important;
        width: 3rem !important;
        height: auto !important;
        position: absolute;
        right: -1.1rem;
        bottom: .6rem;
    }

    .slideService .text p:nth-of-type(2) { margin-bottom: 1rem; }

    .slideService .modal-content > div {
        display: flex;
        flex-direction: column;    
    }

    .slideService .modal-content h3:nth-of-type(1) { bottom: 2rem}
    .slideService .modal-content h3 {
        position: relative;
        bottom: .5rem;
    }

    .slideService .price { margin-top: -.5rem;}
}

@media (max-width: 600px) {
    .slideService > div { width: 100% }

    .slideService .modal {
    }
    .slideService .modal-content {
        width: 90%;
        flex-direction: column;
        max-height: auto;
    }

    .slideService .modal-content img{ width: 100% }

    .slideService .modal-content > div {
        padding: 0;
        padding-top: 1rem;
    }

    .slideService > img { width: 100% }

    .slideService .modal-content h2 { font-size: 1.5rem }
}
