footer {
    position: relative;
    max-width: 100%;
    margin: 2rem auto;
    margin-top: 10rem;
    padding-bottom: 3rem;
    text-align: center;
    background: linear-gradient(to right, #111, #333 );
    z-index: 1 !important;
}

footer::before {
    content: 'a';
    color: rgba(255, 0, 0, 0);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    line-height: .3rem;
    background: linear-gradient(to right,#e9dfb1, #f2b14a)
}

.logoFooter {
    position: relative;
    padding-top: 3rem;
}

.logoFooter > div{
    position: relative;
    display: flex;
    justify-content: center;
}

.logoFooter div img {
    position: absolute;
    width: 100px;
}

.logoFooter h3 {
    margin-top: 7rem;
}

@keyframes logoCircle{
to {
    transform: rotate(360deg)
}
}
.logoFooter div img:nth-of-type(2) {
animation: logoCircle 30s linear infinite;
}

footer .contact {
    margin: 1rem 0;
    display: flex;
    justify-content: space-around;
}

footer .contact img {
    width: 40px;
    height: 35px;
}
footer .contact div:nth-of-type(2) img { width: 50px }

footer .contact h3 { opacity: .9 }

footer hr {
    width: 90%;
    margin: 0 auto;
    margin-top: 1rem;
    opacity: .4;
}

footer .copyright {
    width: 90%;
    margin: 2rem auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

footer .social {
    position: absolute;
    gap: 1rem;
    display: flex;
    left: 50%;
    transform: translateX(-50%);
}

footer .social i { 
    color: white;
    font-size: 2rem;
    transition: .4s;
    opacity: .8;
}

footer .social i:hover { color: #f2b14a ; opacity: 1; }

footer .copyright p > a { transition: .4s }
footer .copyright p > a:hover { color: white }


@media (max-width: 700px) {
    footer { margin-top: 2rem }
    footer .copyright {
        display: block;
        margin-top: 3.5rem;
    }
    footer .social {
        margin-top: -3.5rem;
    }

    footer.social::after {
        content: '../../public/img/avatar_1.png';
        width: 300px;
        height: 300px
    }

    footer .contact div img {width: 1.5rem; height: 1.5rem}
    footer .contact div:nth-of-type(2) img {width: 2rem; height: 1.5rem}
    footer .contact div h3 { font-size: 1rem; }
    footer .contact { display: none}
}