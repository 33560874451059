.slideTeam {
    outline: 0;
    max-width: 90%;
    margin: 6rem auto;
    text-align: center;
}

.slideTeam .slick-dots { display: none !important }

.slideTeam .slick-slide > div {
    z-index: 300;
    position: relative;
    margin-bottom: -1rem;
    margin: 0 .5rem;
    opacity: .7;
}

.slideTeam .card {
    position: relative;
    border-radius: 2rem;
    border: 5px solid #333;
}

.slideTeam .card a {
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: #fff;
    font-size: 1.3rem;
    opacity: .8;
    transition: .4s;
}
.slideTeam .card a:hover {
    transform: scale(1);
}

.slideTeam .slick-current > div {
    opacity: 1;
}

.slideTeam .slick-slide > div .card {
    display: block !important;
    text-align: left;
}

.slideTeam .slick-slide > div .card h3 { color: #f2b14a; font-size: 1rem; margin-bottom: -5px; padding-top: 5px; padding-left: .5rem;}
.slideTeam .slick-current > div .card h3 { color: white }
.slideTeam .slick-current > div .card a { color: #f2b14a; font-size: 1.8rem }
.slideTeam .slick-slide > div .card h2 { font-size: 1.2rem !important ; padding-left: 1.3rem }
.slideTeam .slick-current > div .card h2 { color: #333 }

.slideTeam .slick-current > div > div div { background: #f2b14a !important }
.slideTeam .slick-current .card { position: relative; border-color: #f2b14a !important }

.slideTeam .slick-slide > div > div div  {
    position: absolute;
    width: 70%;
    background-color: #333;
    border-bottom-left-radius: 2rem;
    border-top-right-radius: 2rem;
    bottom: -2px;
    left: -2px;
}

.slideTeam .slick-slide img {
    width: 100%;
    height: 100%;
    border-radius: 1.6rem;
}

.slideTeam .slick-arrow { display: none !important}

.slideTeam > img {
    display: flex;
    max-width: 90%;
}

.slideTeam::after {
    content: "";
    background: url('../../public/img/bg_line_razor.png') center no-repeat;
    background-size: cover;
    position: relative;
    display: block;
    width: 100%;
    height: 5rem;
    margin-top: 2rem;
    right: -5.5%
  }

@media (max-width: 800px) {
    .slideTeam::after {
        background: url('../../public/img/bg_line_razor_mobile.png') center no-repeat;
    }
}

@media (max-width: 600px) {
    .slideTeam .slick-slide > div > div div {
        width: 85%
    }
}