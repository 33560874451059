#popModal {
    display: none;
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    background: rgba(24, 24, 24, 0.753);
    z-index: 10001 !important;
  }
  
  #popModal .container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate( -50%, -50%);
    width: 70%;
    height: 500px;
    display: flex;
    border-radius: 1rem;
    justify-content: center;
    background: white;
    z-index: 1000 !important;
  }
  
  #popModal .container div:nth-of-type(2) h2 {
    position: absolute;
    z-index: 10;
    right: 10px;
  }
  
  #popModal .container img {
    position: relative;
    width: 100%;
    height: 100%;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
  
  #popModal .container div:nth-of-type(1) > img {
    top: 2rem;
    height: auto;
  }
  
  #popModal .container div { 
    width: 50%;
    position: relative; 
  }
  
  #popModal div form {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    position: relative;
  }
  
  #popModal div form label { cursor: url('../../public/img/cursorPointer.png') 1 1, pointer !important }
  #popModal div form label {
    color:rgb(245, 177, 50);
    font-weight: bold;
    letter-spacing: 2px;
    background: white;
    padding: 0 10px;
    position: relative;
    top: .5rem;
    display: flex;
    align-items: center;
    gap: 1rem
  }
  
  #popModal div form label input[type="radio"] {
    position: absolute;
    transform: scale(1.2);
    right: 0
  }
  
  @keyframes iconClub {
    0% { transform: scale(1.1) }
    50% { transform: scale(1) }
    100% { transform: scale(1.1) }
  }
  #popModal .container div form label img {
    width: 60px;
    animation: iconClub 3s linear infinite
  }
  #popModal .container div form label:nth-of-type(3) img {width: 60px}
  
  #popModal div form button {
    border-radius: 0;
    color: white;
    background: #f2b14a;
    letter-spacing: 3px;
    font-size: 1.2rem;
    font-family: Mongolian;
    font-weight: bold;
    transition: .4s;
    margin-top: 1rem;
    line-height: 3rem;
    border-radius: 1rem;
  }
  
  #popModal div form button:hover { background: #ffc66b; border-color: #ffc66b }
  #popModal div form button:focus { border-color: #333; }
  
  #popModal div form input::placeholder {
    opacity: .6
  }
  
  #popModal .container div:nth-of-type(1) form p {
   color: #999;
   margin-top: 2rem;
  }
  #closePopup:hover {
    color: #ffa052;
    transition: .4s;
    cursor: url('../../public/img/cursorPointer.png') 1 1, pointer !important
  }

  @keyframes whatsAnim {
    0% { transform: scale(1.1); }
    50% { transform: scale(1); }
    100% { transform: scale(1.1); }
  } 

  .whatsButton img {
    position: absolute;
    z-index: 10;
    width: 60px;
    height: 60px;
    right: 0;
    bottom: 0;
    animation: whatsAnim 2s linear infinite;
  }

  .whatsButton {
    position: fixed;
    z-index: 10;
    width: 5%;
    /* height: 100%; */
    right: 2rem;
    bottom: 2rem;
    background-color: red;
  }

  @media (max-width: 600px) {
    .whatsButton {
      right: 1rem;
    }
    #popModal div div:nth-of-type(2) img { display: none }

    #popModal div div:nth-of-type(2) {
        position: absolute;
        right: 5px;
        top: 5px;
        color:#ececec
    }

    #popModal div div:nth-of-type(1) { width: 100% }

    #popModal div form > p {
        margin: 1rem 0;
        margin-top: .5rem;
    }

    #popModal .container { width: 90% }
  }