.Depoiments {
    max-width: 100%;
    margin: 2rem auto;
    text-align: center;
}

.Depoiments .card div {
    margin: 0 auto;
}

.Depoiments .card img {
    width: 100%;
    max-width: 300px;
    border-radius: 50%;
    margin: 0 auto;
    border: 7px solid #222;
}

.Depoiments .card p {
    margin: 1rem 0
}

.Depoiments .card p i {
    padding: 10px
}

.Depoiments .slick-slider {
    position: relative;
    width: 90%;
    margin: 0 auto;
    transform: scale(.8);
}

.Depoiments .slick-slide {
   opacity: .5;
}

.Depoiments .slick-current {
    left: 50%;
    margin: 0 auto;
   opacity: 1;


}
.Depoiments .slick-current img {
    border-color: #f2b14a
}

@media (max-width: 400px) {
    .Depoiments img { max-width: 70% }
}