#shopClose {
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: #666;
    font-size: 1.5rem;
    font-weight: normal;
    cursor: url('../../public/img/cursorPointer.png') 1 1, pointer !important
}

#shopClose:hover { color: #ffa052 }
@media (max-width: 600px) {
    #shopClose {
        top: 2rem;
        right: 2rem;
        color: #999;
        z-index: 100
    }
}

.BarberShop {
    max-width: 100%;
    height: 600px;
    margin: 2rem auto;
    text-align: center;
}
.BarberShop > img { width: 400px }

.BarberShop .Products {
    position: relative;
    width: 80%;
    margin: 0 auto
}

.BarberShop ul {
    display: flex;
    justify-content: center;
    list-style: none;
    gap: 2rem;
}

.BarberShop ul li {
    cursor: url('../../public/img/cursorPointer.png') 1 1, pointer !important
}
.BarberShop ul li:hover{
    color: #f2b14a;
    font-weight: bold;
}

.BarberShop .Products {
    position: relative;
    width: 80%;
    margin: 0 auto;
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    gap: .5rem
}

.BarberShop .Products img {
    width: 100%;
    border-radius: 5px;
    cursor: url('../../public/img/cursorPointer.png') 1 1, pointer !important;
    opacity: .7;
    transition: .4s;
    z-index: 0;
}

.BarberShop .Products img:hover {
    opacity: 1;
    border: 3px solid #f2b14a
}

/*
.BarberShop .Products img.show {
    position: absolute;
    opacity: 1;
    z-index: 1;
    max-width: 90%;
    max-height: 120%;
    border: 5px solid #f2b14a;
}*/

.BarberShop .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.BarberShop .modalContent {
    position: relative;
    display: flex;
    min-width: 50%;
    max-width: 900px;
    max-height: 500px;
    width: 100%;
    height: 80%;
    background-color: #fff;
    padding: 20px;
    border-radius: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 100
}

.BarberShop .modalContent span {
    width: 60%;
    display: flex;
    position: relative;
}
.BarberShop .modalContent span img {
    max-width: 100%
}

@keyframes infoPisca {
    0% { transform: scale(.9) }
    50% { transform: scale(1.1) }
    100% { transform: scale(.9) }
}

.BarberShop .modalContent span i:hover {color: #ffa052}
.BarberShop .modalContent span i {
    position: absolute;
    left: 1rem;
    top: 1rem;
    z-index: 3;
    animation: infoPisca 2s linear infinite
}

#fog {
    overflow-x: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    background: url("../../public/img/shop_fog.png");
    background-size: 200% auto;
    background-position: 0 bottom;
    animation: fogAnim 40s linear infinite;
    margin-left: -2% !important;
    right: 0;
    border-radius: 1rem;
    z-index: 1;
    opacity: .6
}

@keyframes fogAnim {
    from { background-position: -0% bottom }
    to { background-position: -200% bottom }
}

.BarberShop .modalContent > div {
    width: 50%;
    padding: 0 2rem;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    text-align: left;
}

.BarberShop .modalContent img {
    max-width: 60%;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    border-radius: 1rem;
    box-shadow: 1px 3px 6px 1px rgba(0, 0, 0, 0.548);
}

.BarberShop .modalContent h2 {
    font-family: AvenirBold;
    letter-spacing: 5px;
    font-size: 2rem;
    color: #f2b14a;
    text-transform: uppercase;
}

.BarberShop .modalContent p {
    color: #444;
}

.BarberShop .modalContent .price {
    position: relative;
    font-size: 1rem;
}

.BarberShop .modalContent .price h3:nth-of-type(1){
    display: none !important;
}
.BarberShop .modalContent h3 {
    position: absolute;
    font-family: AvenirBold;
    font-size: 1.4rem;
    color: #f2b14a;
    display: flex;
    bottom: 1rem;
    font-weight: lighter;
    letter-spacing: 3px;
    text-transform: uppercase;
}
.BarberShop .modalContent h3:nth-of-type(1) {
    position: absolute;
    font-weight: lighter;
    letter-spacing: 3px;
    bottom: 2.4rem;
    left: -.8rem;
    color: #444 !important;
    transform: scale(.8);
    opacity: .8;
}

.BarberShop .modalContent h3:nth-of-type(1)::after{
    content: '';
    position: absolute;
    width: 100%;
    top: 50%;
    border-bottom: 2px solid #b9b9b9;
    transform: rotate(5deg);
    opacity: .6;
}

.BarberShop .modalContent h3:nth-of-type(1)::before{
    content: '';
    position: absolute;
    width: 100%;
    top: 50%;
    border-bottom: 2px solid #818181;
    transform: rotate(-5deg);
    opacity: .6;
}

.BarberShop .modalContent button {
    border: 3px solid #555;
    border-radius: 1rem;
    font-size: 1.2rem;
    font-weight: bold;
    font-family: Mongolian;
    letter-spacing: 3px;
    background: #f2b14a;
    height: 4rem;
    transition: .4s;
    cursor: pointer;
    color: #222;
    text-transform: uppercase;
}
.BarberShop .modalContent button:hover { 
    color: white ;
    font-size: 1.1rem;
    border-color: #444;
    background: linear-gradient(#f2b14a, #bb8253)
}

#infoModal {
    position: absolute;
    text-align: justify;
    width: 70%;
    color: #222;
    background: white;
    margin: 0.6rem;
    padding: 1rem;
    padding-top: 1.5rem;
    border-radius: 1rem;
}

#infoWrap {
    position: absolute;
    width: 100%;
    z-index: 2;
}


.BarberShop .scroll img {
    display: none;
}


@media (max-width: 900px){
    .BarberShop {height: 450px}

    .BarberShop .modalContent {
        max-width: 580px !important;
        max-height: 70% !important;
    }
    
    .BarberShop .modalContent img {
        max-width: 100%;
        width: 280px;
        height: 280px;
    }

    .BarberShop .modalContent > div {
        display: flex;
        flex-direction: column;    
    }

    .BarberShop .modalContent h2 { font-size: 1.5rem }
    .BarberShop .modalContent h3:nth-of-type(1) { bottom: 2rem}
    .BarberShop .modalContent h3 {
        position: relative;
        bottom: .5rem;
    }

    .BarberShop .price { margin-top: 1rem;}

    .BarberShop .text {
        -webkit-overflow-scrolling: touch;
        overflow-y: auto;
        margin: 1rem 0;
        height: 3rem;
    }

    .BarberShop .text::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0) !important;
        opacity: 0 !important;
      }
    .BarberShop .text::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0) !important;
        opacity: 0 !important;
    }

    .BarberShop .scroll img { display: block ;}
    .BarberShop .scroll {
        display: block;
        position: relative;
        width: 100%;
        display: flex;
    }
    
    .BarberShop .scroll .text { width: 97% !important;}
    .BarberShop .scroll img { 
        box-shadow: none !important;
        width: 3rem !important;
        height: auto !important;
        position: absolute;
        right: -1.1rem;
        bottom: .6rem;
    }
}

@media (max-width: 600px){
    .BarberShop { height: 370px }

    .BarberShop > img { width: 95% }

    .BarberShop .Products { width: 90% }

    .BarberShop > div { width: 100% }

    .BarberShop .modalContent {
        flex-direction: column;
        max-height: auto;
    }

    .BarberShop .modalContent img{ width: 100% }

    .BarberShop .modalContent > span { width: 100% }

    .BarberShop .modalContent > div {
        width: 100%;
        padding: 0;
        padding-top: 1rem;
    }

}