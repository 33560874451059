* { margin: 0; padding: 0; box-sizing: border-box; font-family: Mongolian;}

html { scroll-behavior: smooth }

html, body, #root {
  max-width: 100%;
  background: linear-gradient(to right, #111, #333 );
  color: #e9e9e9; 
}

@font-face { font-family: Empera; src: url('../public/font/Empera.ttf'); }
@font-face { font-family: Mongolian; src: url('../public/font/Mongolian.otf'); }
@font-face { font-family: Vanilla; src: url('../public/font/Vanilla.ttf'); }
@font-face { font-family: AvenirBold; src: url('../public/font/AvenirBold.otf'); }

::selection {
  background-color: #b8733b; /* Cor de fundo da seleÃ§Ã£o */
  color: #ffffff; /* Cor do texto da seleÃ§Ã£o */
}

::-webkit-scrollbar-track { border-radius: 1rem; background-color: #e7e7e7 }
::-webkit-scrollbar {
    width: 5px;
    background: #00000000;
}
::-webkit-scrollbar-thumb {
    background: linear-gradient(to right, #ebcb98, #ffa052);
	border-radius: 10px;
}
body {
  cursor: url('../public/img/cursorDefault.png')  1 1, pointer !important;
}

a, button { cursor: url('../public/img/cursorPointer.png') 1 1, pointer !important }

b, a { color: #f2b14a }



.box { overflow-x: hidden !important }
/* Hero */
header {
  position: relative;
  width: 100%;
  height: 720px;
}

.menu_desktop {
  position: fixed !important;
  width: 100%;
  line-height: 2rem;
  padding: 2% 0;
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 2;
  background: linear-gradient(#111111a6 60%, #11111100);
  padding-bottom: 3rem;
}

.menu_desktop > div{
  position: relative;
  display: flex;
  top: -2rem;
  cursor: url('../public/img/cursorPointer.png') 1 1, pointer !important
}

.menu_desktop img {
  position: absolute;
  width: 100px;
}

@keyframes logoCircle{
  to {
    transform: rotate(360deg)
}
}
.menu_desktop > div img:nth-of-type(2) {
  animation: logoCircle 30s linear infinite;
}

.menu_desktop ul {
  display: flex;
  gap: 2rem;
  list-style: none;
  font-family: Empera;
  font-weight: bold;
  letter-spacing: 1px;
  margin-top: 2rem
}

.menu_desktop ul a{
  color: white;
  text-decoration: none;
  transition: .4s;
}

.menu_desktop ul li { transition: .3s; font-size: .9rem}
.menu_desktop ul li:hover { transform: scale(1.1); letter-spacing: 1px; }

.menu_desktop ul li a:hover {
  color: #f2b14a;
}

@media (max-width: 800px) {
  .menu_desktop { justify-content: space-between; padding: 4%}
  .menu_desktop ul, .menu_desktop a { display: none }
}

.menu_desktop button {
  margin-top: 1.5rem !important;
  color: white;
  font-size: .8rem;
  letter-spacing: 2px;
  padding: 1rem;
  border: 3px solid rgb(228, 176, 33);
  border-radius: 2rem;
  background: none;
  cursor: pointer;
}

#menuButton { display: none }
#menuMobile {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  z-index: 100; 
  left: 0;
  top: 0;
  background: #222222bb;
}

#menuMobile div {
  position: relative;
  margin: 0 auto;
  margin-top: 2rem;
  width: 90%;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  background-color: #fff;
  border-radius: 1rem;
  text-align: center;
  padding: 1rem;
  padding-top: 3rem;
  background: #fff;
  gap: 1rem;
  z-index: 100;
}

#closeMenu {
  position: absolute;
  top: .5rem;
  right: 1rem;
  color: #666;
}

#closeMenu:hover { color: #f2b14a }

#menuMobile div a {
  width: 80vw;
  line-height: 2.5rem;
  color: #222;
  display: grid;
  transition: .4s;
  text-decoration: none;
  border: 2px solid #444;
  border-radius: 1rem;
  background: #f2b14a;

}

#menuMobile div a:hover {
  color: #fff;
  letter-spacing: 1px;
}

@media (max-width: 800px) {

  #menuButton {
    display: block;
  }
}
/* Hero */
hero {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 2fr 1fr 1fr;
  align-items: center;
  padding: 0 4%;
  background: url('../public/img/hero_1.jpg') center no-repeat;
  background-size: cover !important;
  letter-spacing: 2px;
  transition: background-image 0.5s ease-in-out;
}

hero::before {
  content: 'a';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  line-height: .3rem;
  color: #00000000;
  background: linear-gradient(to right,#e9dfb1, #f2b14a) !
}

hero::after {
  content: ".";
  position: absolute;
  bottom: .3rem;
  right: 0;
  width: 50%;
  line-height: .5rem;
  color: #00000000;
  background: linear-gradient(to right,#f7db61, #f2b14a)
}

hero .title-barber {
  width: 50%;
  align-self: flex-end;
  opacity: 0 !important;
}

hero .title-barber img {
  width: 60%;
}

hero .title-barber h3 {
  margin-top: 1rem;
}

hero #titleBarber {
  
}

hero #titleKids {
  display: none;
  opacity: 0;
  transition: opacity 2.8s ease-in-out;
  position: absolute;
  text-align: center;
  left: 0;
  width: 100%;
  padding-bottom: 5rem;
  background: linear-gradient(#00000000, #000000);
}

hero #titleMencare {
  display: none;
  opacity: 0;
  transition: opacity 2.8s ease-in-out;
}

hero::before {
  content: 'a';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  line-height: .3rem;
  color: #00000000;
  background: linear-gradient(to right,#e9dfb1, #f2b14a) !important
}

hero .social_midia {   align-self: flex-center;
}

hero .social_midia a {
  margin-left: 1rem;
  color: white;
}
  
hero .social_midia i { font-size: 3.5rem; transition: .4s }
hero .social_midia a:hover { color: #f2b14a; cursor: pointer; }

hero .social_midia i:nth-child(n+2){ margin-left: 2rem }
hero .social_midia img {
  fill: red;
  opacity: .5;
}

hero .bullets {
  position: absolute;
  right: 3%;
  top: calc(50% - 8rem);
}

hero .bullets div {
  width: 2rem;
  height: 2rem;
  border: 0.5rem solid #e9e9e9;
  border-radius: 50%;
  margin: 1.5rem 0;
  transition: background-color 0.3s ease-in-out;
}

hero .bullets div.actived {
  border: 0.5rem solid rgb(245, 177, 50);
  background-color: #e9e9e9;
}

@media (max-width: 600px) {
  header { height: 95vh }

  hero {
    background: url('../public/img/hero_2.jpg') center no-repeat;
  }

  hero .bullets {
    right: auto;
    top: auto;
    bottom: 1rem;
    display: flex;
    gap: 1rem;
    left: 50%;
    transform: translateX(-50%) scale(.7);
  }
}

#workModal {
  display: none;
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  background: rgba(24, 24, 24, 0.753);
  z-index: 10001 !important;
}

#workModal .container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate( -50%, -50%);
  width: 70%;
  height: 500px;
  display: flex;
  border-radius: 1rem;
  justify-content: center;
  background: white;
  z-index: 1000 !important;
}

#workModal .container div:nth-of-type(2) h2 {
  position: absolute;
  z-index: 10;
  right: 10px;
}

#workModal .container img {
  position: relative;
  width: 100%;
  height: 100%;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

#workModal .container div:nth-of-type(1) img {
  top: 2rem;
  height: auto;
}

#workModal .container div { 
  width: 50%;
  position: relative; 
}

#workModal div form {
  width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  position: relative;
}

#workModal div form > p {
  position: relative;
  top: 1rem;
  color: #999999
}

#workModal div form label {
  color:rgb(245, 177, 50);
  font-weight: bold;
  letter-spacing: 2px;
  background: white;
  width: max-content;
  padding: 0 10px;
  position: relative;
  left: 1rem;
  top: 1rem
}

#workModal div form input {
  line-height: 3rem;
  font-size: 1rem;
  padding: 0 10px
}

#workModal div form input:nth-of-type(3) {
  color: #666;
  cursor: url('../public/img/cursorPointer.png') 1 1, pointer !important
}

#workModal div form button {
  border-radius: 0;
  color: white;
  background: #f2b14a;
  letter-spacing: 3px;
  font-size: 1.2rem;
  font-family: Mongolian;
  font-weight: bold;
  transition: .4s;
  color: #222;
  border: 2px solid #555;
  border-radius: 1rem;
}

#workModal div form button:hover { background: #ffbb4d; color: #fff }
#workModal div form button:focus { border-color: #333; }

#workModal div form input::placeholder {
  opacity: .6
}

#workModal .container div:nth-of-type(1) {
 
  
}
#modalClose:hover {
  color: #ffa052;
  transition: .4s;
  cursor: url('../public/img/cursorPointer.png') 1 1, pointer !important
}

/* About */
section.about {
  width: 100%;
  margin-top: 2rem;
}

section.about::after {
  content: "";
  background: url('../public/img/bg_line_scissors.png') center no-repeat;
  background-size: cover;
  display: block;
  width: 100%;
  height: 5rem;
}

section.about .history {
  padding: 4%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.history button, .kids button {
  margin-top: 1.5rem !important;
    color: white;
    font-size: .8rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 1rem;
    border: 3px solid rgb(228, 176, 33);
    border-radius: 2rem;
    background: none;
}

/* About Text */
.history div:nth-of-type(1) {
  width: 70%;
}
.adout div:nth-of-type(1) { width: 70% }

.history span, .kids span {display: flex; gap: 1rem;}
.history h2, .kids h2, .kids h2 span, .adout h2 span {
  font-family: Empera;
  font-size: 3rem;
  letter-spacing: 3px;
}

.history h2:nth-of-type(2), .kids h2 span, .adout h2 span { color: #f2b14a }

.history h3, .kids h3 {
  font-family: Vanilla;
  font-size: 1.5rem;
  font-style: italic;
  margin-left: 2rem;
  margin-top: -1rem;
}

.history .text p {
  width: 100%;
  margin-top: 1rem;
  font-size: 20px;
}

/* About Image*/
.history div:nth-of-type(2) {
  display: flex;
  position: absolute;
  right: 6%;
}

.history figure:nth-of-type(1) { transform: rotate(-10deg); transition: .4s }
.history figure:nth-of-type(2) { transform: rotate(10deg); transition: .4s }

.history figure:nth-of-type(1):hover { 
  transform: scale(1.2);
  margin-right: -4rem;
  z-index: 2;
}

.history figure:nth-of-type(2):hover {
  z-index: 2;
  transform: scale(1.1);
  margin-left: -4rem;
}

.history figure img.show{ border-color: #f2b14a; transform: scale(1.1); }
.history figure img{
  width: 220px;
  border: 5px solid #ccc;
  border-radius: 1rem;
}

.history figure:nth-of-type(2) img {
  margin-left: -5rem;
}

#titleMencare { display: none !important }



@media (max-width: 800px) {
  body { cursor: default }

  #workModal .container div:nth-of-type(1) {
    width: 100%;
  }

  /* Button Closed Work*/
  #workModal .container div:nth-of-type(2) > h2 {
    display: block;
    position: absolute;  
  }

  .history { flex-direction: column !important ; position: relative}

  .history .text p { width: 100% }
  .history div:nth-of-type(1){
    width: 100%
  }
  .history div:nth-of-type(2) {
    position: relative;
    margin: 0 auto;
    margin-top: 3rem;
    right: auto;
  }

  .kids {
    margin-top: 3rem;
    flex-direction: column-reverse ;
  }

  .kids div:nth-of-type(2) {
    width: 100% !important;
    padding-left: 4% !important;
  }

  .kids div:nth-of-type(1) div {
    left: 215px !important;
    top: 48px !important;
    border: 2px solid red;
    width: 190px !important;
  }

  .kids div:nth-of-type(1) div > div {
    left: 52% !important;
    top: 45% !important;
  }

  .kids div:nth-of-type(1) > img.show {
    height: 470px
  }

  .kids img {
    margin-top: 4rem;
    width: 100vw;
    margin-left: 4%;
    transform: scale(1.1);
  }

  
  section.about::after {
    background-image: url('../public/img/bg_line_scissors_mobile.png');
    width: 100%;
  }
}

@media (max-width: 600px) {
  html, body { cursor: default !important }

  #workModal .container { width: 90% }

  #workModal div div:nth-of-type(2) img {display: none}

  #workModal div div:nth-of-type(2) {
    display: block;
    position: absolute;
    right: 5px;
    top: 5px
  }

  hero #titleBarber, hero #titleMencare {
    display: none;
    opacity: 0;
    transition: opacity 2.8s ease-in-out;
    position: absolute;
    text-align: center;
    left: 0;
    width: 100%;
    padding: 20% 0;
    padding-top: 30%;
    top: 0;
    background: linear-gradient(#000000, #00000000);
  }

  hero #titleBarber img, hero #titleKids img, hero #titleMencare img {
    width: 50%;
    margin-top: 2rem;
  }

  hero .social_midia { display:  none }

  .history div:nth-of-type(1) img {
    width: 90%;
  }

  .adout div:nth-of-type(1) img {
    width: 100%;
  }

  .history div:nth-of-type(2) {
    transform: scale(.9) !important;
  }

  .adout span {
    float: right;
    left: -5rem;
  }

  .kids h2, .adout h2 span { flex-direction: column }

  .kids h2 span, .adout h2 span{
    margin-top: -1rem;
  }

  .kids > div:nth-of-type(1) { left: -70px }

  .kids div:nth-of-type(1) div {
    left: 205px !important;
  }

  .kids div:nth-of-type(2) img {width: 70%;}

}

@media (max-width: 420px) {
  .kids div:nth-of-type(1) div {
    margin-left: -10px !important;
  }

  .slideTeam .slick-slide > div > div div {
    width: 85%
  }
  .kids div div div.box { margin-left: 0rem !important}

}

@media (max-width: 410px) { .kids div:nth-of-type(1) div { margin-left: -10px !important } }
@media (max-width: 400px) { 
  .kids div:nth-of-type(1) div { margin-left: -15px !important } 
  .kids div div div.box { margin-left: 0rem !important}
}
@media (max-width: 390px) { .kids div:nth-of-type(1) div { margin-left: -20px !important } }
@media (max-width: 380px) { .kids div:nth-of-type(1) div { margin-left: -30px !important } }



/* KIDS */
.kids {
  display: flex;
  justify-content: space-between;
}

.kids > div:nth-of-type(1) {
  position: relative;
  display: flex;
}

.kids div:nth-of-type(1) div {
  position: absolute;
  left: 182px;
  top: 6px;
  border: 1px solid #000;
  width: 180px;
  opacity: .8;
  border-radius: 1rem;
} 

.kids div:nth-of-type(1) div video {
  width: 100%;
  background-size: cover !important;
  background-position: center;
  border-radius: 1rem
} 

.kids div:nth-of-type(2) {
  width: 50%;
  padding-right: 4%;
}

.kids div:nth-of-type(1) img {
  width: 100%;
  z-index: 1;
  height: 490px;
  cursor: url('../public/img/cursorPointer.png') 1 1, pointer !important
}

@keyframes buttonPlay{
  0% { transform: translate(-50%) scale(.9) }
  50% { transform: translate(-50%) scale(1) }
  100% { transform: translate(-50%) scale(.9) }
}

.kids div:nth-of-type(1) div > div {
  width: 3.2rem !important;
  position: absolute;
  color: white;
  left: 52%;
  top: 45%;
  transform: translate(-50%);
  font-size: 50px;
  z-index: 99999 !important;
  border: none;
  animation: buttonPlay 1s infinite
}


.kids h2 {
  display: flex;
  gap: 1rem;
}

.kids .text p {
  margin-top: 1rem;
  font-size: 20px;
}

.kids img {
  max-width: 400px
}

.slideService > img {
  width: 100%;
  margin: 1rem 0
}

#bgMobile {
  position: absolute;
    top: 50%;
    left: 50%;
    margin: 0 auto;
    transform: translate(-50%, -50%);
    width: 100%;
    border-radius: 1rem;
    height: 100%;
}


