.promotion-timer {
    width: 95%;
    line-height: 4rem;
    position: fixed;
    bottom: 5px;
    background: linear-gradient(#1111113f, #080808);
    border-bottom: 2px solid #f2b14a;
    color: #fff;
    padding: 10px;
    border-radius: 1rem;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    text-align: center;
    z-index: 40;
    left: 2.5%;
  }
  
  .promotion-timer h2 { opacity: .6 }
  .promotion-timer span {
    position: relative;
    padding-bottom: 1rem;
    margin: 0 2px;
    width: 5rem;
    background: #cccccc1e;
    border-radius: 1rem;
    box-shadow: 1px 3px 6px 1px rgba(0, 0, 0, 0.548);
  }
  
  .promotion-timer span b {
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: -2.5rem;
  }
  /* Estilos adicionais conforme necessário */
  .whatsButton {
    z-index: 0 !important;
  }

  #promotionClose {
    position: absolute;
    right: 10px;
    top: -1.2rem;
    font-size: .7rem;
    cursor: url('../../public/img/cursorPointer.png') 1 1, pointer !important
  }

  #promotionClose:hover{
    color: orange  
  }

  @media (max-width: 600px) {
    #promotionClose { top: -1.4rem ; right: auto }
  }